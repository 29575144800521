body {
  color: var(--color-text-primary);
}

p {
  line-height: 1.7em;
}

* {
  box-sizing: border-box;
}

:root {
  --border-color: #e6e8ea;
  --border: 1px solid var(--border-color);
  --border-radius: 12px;
  --color-text-primary: #000b12;
  --color-text-secondary: #808589;
  --color-green: #059669;
  --color-highlight-purple: #5629c6;
  --color-gray-light: #f4f4f4;
  --frame-padding: 0px;
}

#calculator-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 85vw;
  max-width: 1200px;
  overflow: hidden;
}

.controls-container,
.c-1,
.c-2,
.c-3,
.c-4,
.c-5 {
  background-color: white;
}

.c-2,
.c-3,
.c-4,
.c-5 {
  z-index: 1000;
}

.c-1 {
  margin-top: 288px;
}

/* medium screen size */
@media (max-width: 1100px) {
  .config-container-wrap {
    grid-template-columns: repeat(2, 1fr) !important;
  }

  #calculator-container {
    grid-template-columns: 1fr 1fr;
  }

  .features-categories {
    display: none;
  }

  .features-categories-mobile {
    display: block !important;
  }

  .feature {
    display: flex;
    justify-content: space-between !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .c-1 {
    margin-top: 260px;
  }

  .c-1-inner {
    display: none;
  }

  .controls-column {
    grid-column: span 2;
  }

  .c-2,
  .c-3 {
    border-top: var(--border);
    border-bottom: var(--border);
  }

  .c-3 {
    border-top-right-radius: var(--border-radius);
    overflow: hidden;
  }

  .c-5 {
    border-top-right-radius: 0 !important;
  }

  .c-4 {
    border-left: var(--border);
    border-bottom-left-radius: var(--border-radius);
  }

  .c-1,
  .c-3 {
    border-right: 0;
  }
}

/* smallest screen size */
@media (max-width: 600px) {
  .config-container-wrap {
    grid-template-columns: repeat(1, 1fr) !important;
    position: relative !important;
    width: 100% !important;
  }

  .config-container-section {
    padding-right: 0 !important;
  }

  #calculator-container {
    grid-template-columns: 1fr;
  }

  .controls-column {
    grid-column: span 1;
  }

  .c-1 {
    margin-top: 12px !important;
  }

  .c-2,
  .c-4 {
    border-right: 0;
    border-bottom: 0;
  }

  .c-4 {
    border-bottom: var(--border);
  }

  .c-2 {
    border-top-right-radius: var(--border-radius);
  }
  .c-3 {
    border-left: var(--border);
    border-top-right-radius: 0 !important;
  }

  .c-4 {
    border-bottom-left-radius: 0 !important;
  }

  .c-5 {
    border-left: var(--border);
    border-bottom-left-radius: var(--border-radius) !important;
  }
}

.tier-price {
  font-size: 50px;
  font-weight: 500;
}

.currency-symbol {
  font-size: 12px;
  color: var(--color-text-secondary);
  font-weight: normal;
  margin-right: 4px;
}

.tier-cadence {
  font-size: 12px;
  color: var(--color-text-secondary);
  font-weight: 400;
  align-self: flex-end;
  margin-bottom: 8px;
}

.feature {
  font-size: 12px;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  height: 48px;
}

.seats-pricing-container {
  padding-bottom: 10px;
  padding-top: 10px;
}

.add-on {
  margin-top: 0;
  font-size: 14px;
  margin-bottom: 0;
  color: var(--color-green);
}

.add-on.label {
  margin-left: 1ch;
}

.tier-pricing-container {
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}

.features-list {
  padding-left: 0;
}

.features-list > li:not(:last-child) {
  margin-bottom: 4px;
}

.feature > .check-icon {
  background: var(--color-green);
  color: white;
  flex-shrink: 0;
  border-radius: 50%;
  flex-grow: 0;
  height: 14px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.check-icon > svg {
  height: 6px;
}

.controls-description {
  font-size: 12px;
  color: var(--color-text-secondary);
  line-height: 1.5;
}

.seats-container {
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.seats-label {
  color: var(--color-text-secondary);
  font-size: 16px;
  font-weight: normal;
}

.seats-count {
  font-size: 38px;
  line-height: initial;
  font-weight: 500;
}

.annual-discount-toggle {
  font-size: 14px;
}

.title-container {
  background: var(--color-gray-light);
  display: flex;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: var(--border);
}

.title-container > * {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
}

/* .tier-information-container {
  height: 240px;
} */

.card-top-section {
  padding: 20px;
}

.card-bottom-section {
  border-top: 1px solid #e6e8ea;
  padding-right: 20px;
  padding-left: 20px;
}

.btn-cta-secondary {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-highlight-purple);
  background-color: white;
  color: var(--color-highlight-purple);
  font-size: 12px;
  padding: 8px 0;
  border-radius: 4px;
  font-weight: 400;
  margin-top: 20px;
  text-decoration: none;
  cursor: pointer;
}

.btn-cta-primary {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-highlight-purple);
  background-color: var(--color-highlight-purple);
  color: white;
  font-size: 12px;
  padding: 8px 0;
  border-radius: 4px;
  font-weight: 400;
  margin-top: 20px;
  text-decoration: none;
  cursor: pointer;
}

.billed-monthly-label {
  font-size: 12px !important;
  margin: 0;
  text-align: center;
  margin-bottom: 8px;
}

.tier-name.empty {
  opacity: 0;
}

.b-t {
  border-top: var(--border);
}

.features-section > .feature {
  border-bottom: var(--border);
  border-right: var(--border);
}

.features-section > .feature:last-of-type {
  border-bottom: 0;
}

.c-2 .feature {
  /* border-left: var(--border); */
}

.c-5 .feature {
  border-right: 0;
}

.c-5 {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  border-right: var(--border);
  overflow: hidden;
}

.c-2 {
  border-left: var(--border);
  border-top-left-radius: var(--border-radius);
  overflow: hidden;
}

.c-2,
.c-3,
.c-4,
.c-5 {
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.dev-mode {
  --frame-padding: 64px;
  padding: var(--frame-padding);
  height: 100vh;
}

.prod-mode {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  --frame-padding: 64px;
  padding: var(--frame-padding);
}

.category-name {
  justify-content: flex-start;
  padding-left: 24px;
  padding-right: 16px;
}

.category-name a {
  color: var(--color-text-primary);
  text-decoration: none;
  border-bottom: 1px dashed var(--color-text-secondary);
}

.c-1 .feature {
  border-right: 0;
}

.c-1-inner {
  border-left: var(--border);
  border-bottom: var(--border);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  overflow: hidden;
}

.tier-information-container {
  border-right: var(--border);
}

.c-5 .tier-information-container {
  border-right: 0;
}

.c-1-inner .title-container {
  justify-content: flex-start;
  padding-left: 24px;
  padding-right: 24px;
}

.config-container-wrap {
  width: 85vw;
  max-width: 1200px;
  position: absolute;
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(5, 1fr);
}

.config-container-section {
  padding-right: 14px;
}

.config-container {
  border-radius: var(--border-radius);
  border: var(--border);
  display: flex;
  overflow: hidden;
}

.details-label {
  display: flex;
  background: var(--color-text-primary);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-size: 13px;
}

.controls-container > * {
  padding: 12px;
  border-bottom: var(--border);
}

.controls-container > *:last-child {
  border-bottom: 0;
}

.controls-container {
  flex-grow: 1;
}

label {
  color: var(--color-text-primary);
  font-size: 12px;
  user-select: none;
  font-weight: 400;
  margin-bottom: 5px;
}

.control label {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input[type="text"] {
  width: 6ch;
  border: var(--border);
  border-radius: 4px;
  padding: 4px 0;
  text-align: center;
  font-size: 10px;
}

.features-categories-mobile {
  display: none;
}

.feature-name {
  color: var(--color-text-secondary);
}
